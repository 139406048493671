import React, { useEffect, useRef, useState } from 'react';
import {
  doDocuSignSetup,
  getConsentURL,
  getDocuSignUser,
  useDocuSignAccount,
} from '../../../../services/api/docusign/docusign';
import {
  Button,
  Card,
  CardHeader,
  DocusignAccount,
  DocusignUser,
  log,
  noop,
  Type,
  useFlags,
  useToast,
} from 'common';
import { useActiveUser, useAuth } from '../../../../Auth';
import SellerSignaturePreview from './SellerSignaturePreview';
import SellerSignatureModal from './SellerSignatureModal';
import { ConnectionStatus } from '../../../../components';
import LogoCardHeader from '../../Connectors/LogoCardHeader';
import DocuSignLogoSVG from '../../../../assets/integration-logos/docuSignLogoSVG';
import axios from 'axios';
import { getUrl } from 'app/src/services/helper';
import { ENVIRONMENT_CONFIG } from 'app/config/hosts';

const Signing: React.FC = () => {
  const apiResponse = useDocuSignAccount();

  const [docuSignAccount, setDocuSignAccount] = useState<DocusignAccount>();
  const [docuSignUser, setDocuSignUser] = useState<DocusignUser>();
  const [setupError, setSetupError] = useState<string>();
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [signatureModalIsOpen, setSignatureModalIsOpen] =
    useState<boolean>(false);
  const activeUserId = useActiveUser()?.id;
  const isOnMountCalled = useRef(false);

  const { docusignDownload } = useFlags();

  async function performSetup(codeValue: string) {
    setInProgress(true);
    const response = await doDocuSignSetup(codeValue);

    if (response.status >= 400) {
      setSetupError('setup error');
      setInProgress(false);
    } else {
      await apiResponse.refetch();
    }
  }

  useEffect(() => {
    if (isOnMountCalled.current) return;
    isOnMountCalled.current = true;

    const queryParams = new URLSearchParams(window.location.search);
    const dsError = queryParams.get('dsError');
    const dsErrorMessage = queryParams.get('dsErrorMessage');
    if (dsError) setSetupError(`${dsError}: ${dsErrorMessage}`);

    const dsCode = queryParams.get('dsCode');
    if (dsCode) {
      performSetup(dsCode).then(noop, log.error);
    }
  }, []);

  const setupHandler = () => {
    window.location.href = getConsentURL();
  };

  useEffect(() => {
    if (
      !docuSignAccount &&
      !apiResponse.isLoading &&
      apiResponse.data &&
      !apiResponse.isError
    ) {
      setDocuSignAccount(apiResponse.data);
    }
  }, [apiResponse]);

  useEffect(() => {
    if (docuSignAccount) {
      getDocuSignUser()
        .then((data) => {
          setDocuSignUser(data);
          setInProgress(false);
        })
        .catch(() => {
          setSetupError('Setup error. Failed to retrieve DocuSign user.');
          setInProgress(false);
        });
    }
  }, [docuSignAccount]);

  const getStatusMessage = (): string => {
    if (setupError) {
      return setupError;
    }

    if (docuSignAccount) {
      if (docuSignUser) {
        return `Connected with: ${docuSignUser.username}`;
      } else {
        return 'Connected';
      }
    }

    return '';
  };

  const { token, isSystemAdmin, role } = useAuth();

  const [isDownloading, setIsDownloading] = useState(false);

  const showToast = useToast();

  const handleDownloadDocument = async () => {
    setIsDownloading(true);
    await axios
      .get(
        `${getUrl(ENVIRONMENT_CONFIG.cfAppHost)}/api/latest/documents/download-zip`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const fileName = `cacheflow_docusign_${Date.now().toString()}.zip`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        showToast.success('Documents downloaded successfully.');
      })
      .catch((e: unknown) => {
        showToast.error(
          'Failed to download documents. Please try again later.'
        );
        throw e;
      })
      .finally(() => setIsDownloading(false));
  };

  return (
    <>
      <Card>
        <LogoCardHeader
          logo={DocuSignLogoSVG}
          connectionComponent={
            (docuSignAccount ?? setupError) && (
              <ConnectionStatus
                isConnected={!!docuSignAccount || !!setupError}
                message={getStatusMessage()}
              />
            )
          }
          name="DocuSign"
        >
          {docusignDownload && (role === 'admin' || isSystemAdmin) && (
            <div className="flex flex-row gap-2">
              <Button
                className="h-auto py-2"
                isLoading={inProgress}
                label={
                  docuSignAccount ? 'Reconfigure DocuSign' : 'Set up DocuSign'
                }
                onClick={setupHandler}
                type={docuSignAccount ? 'secondary' : 'primary'}
              />
            </div>
          )}
        </LogoCardHeader>
        <div className="py-4">
          <Type paragraph>
            Connecting DocuSign to Cacheflow allows on-the-fly creation of
            signable contracts based on proposals.
          </Type>

          {setupError && (
            <div className="flex items-center">
              <p className="text-red">{setupError}</p>
            </div>
          )}
        </div>
      </Card>

      {activeUserId && (
        <Card>
          <SellerSignaturePreview
            openSignatureModal={() => setSignatureModalIsOpen(true)}
            userId={activeUserId}
          />
        </Card>
      )}

      {activeUserId && (
        <SellerSignatureModal
          closeModal={() => setSignatureModalIsOpen(false)}
          isOpen={signatureModalIsOpen}
          userId={activeUserId}
        />
      )}

      <Card>
        <CardHeader name="Download Document">
          {' '}
          <Button
            className="h-auto py-2"
            isLoading={isDownloading}
            label="Download"
            onClick={handleDownloadDocument}
          />
        </CardHeader>
        <div className="flex flex-row justify-between items-center w-4/5">
          <div>
            Download all your signed documents from Docusign for the proposals
            in Cacheflow here. This may take a few mins depending on the data
            volume.
          </div>
        </div>
      </Card>
    </>
  );
};

export default Signing;
