import React from 'react';
import MetadataReportsPage from './MetadataReportsPage';
import { Button, useFlags, useToast } from 'common';
import axios from 'axios';
import { getUrl } from 'app/src/services/helper';
import { ENVIRONMENT_CONFIG } from 'app/config/hosts';
import { useAuth } from 'app/src/Auth';

const ReportsPage: React.FC = () => {
  const { token, role, isSystemAdmin } = useAuth();
  const [isLoading, setIsLoading] = React.useState(false);
  const { exportData } = useFlags();

  const showToast = useToast();

  const handleDownloadDocument = async () => {
    setIsLoading(true);
    await axios
      .get(
        `${getUrl(ENVIRONMENT_CONFIG.cfAppHost)}/api/latest/documents/download`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setIsLoading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        const fileName = `cacheflow_data_${Date.now().toString()}.xlsx`;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        showToast.success('Data exported successfully.');
      })
      .catch((e: unknown) => {
        showToast.error('Failed to export data. Please try again later.');
        throw e;
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <div className="flex flex-col">
      {exportData && (role === 'admin' || isSystemAdmin) && (
        <div className="flex justify-between items-center px-4 py-3 h-full ">
          <div className="flex items-center text-center font-semibold text-md">
            Export all your customer, subscription, proposal, invoice and
            transaction data here. This may take a few mins depending on the
            data volume.
          </div>
          <Button
            isLoading={isLoading}
            label="Export All Data"
            onClick={async () => {
              await handleDownloadDocument();
            }}
          />
        </div>
      )}

      <MetadataReportsPage />
    </div>
  );
};

export default ReportsPage;
